import { combineReducers } from 'redux';

import { State, Type } from '../actions/utils';

import initialState from './initial';
import {
  clearedDefaults,
  failedDefaults,
  requestedDefaults,
  succeededDefaults,
  succeededReplacePayload,
} from './utils';

export const achRelationships = (state: any = initialState.cashiering.achRelationships, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.CASHIERING_GET_ACH_RELATIONSHIPS):
      return clearedDefaults(state);
    case State.actionRequested(Type.CASHIERING_GET_ACH_RELATIONSHIPS):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.CASHIERING_GET_ACH_RELATIONSHIPS):
      return succeededReplacePayload(state, action);
    case State.actionFailed(Type.CASHIERING_GET_ACH_RELATIONSHIPS):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
    case State.actionSucceeded(Type.SWITCH_ACCOUNT):
    case State.actionSucceeded(Type.CREATE_ACCOUNT):
      return initialState.cashiering.achRelationships;
    default:
      return state;
  }
};

export const createACHRelationship = (state: any = initialState.cashiering.createACHRelationship, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.CASHIERING_CREATE_ACH_RELATIONSHIP):
      return clearedDefaults(state);
    case State.actionRequested(Type.CASHIERING_CREATE_ACH_RELATIONSHIP):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.CASHIERING_CREATE_ACH_RELATIONSHIP):
      return succeededReplacePayload(state, action);
    case State.actionFailed(Type.CASHIERING_CREATE_ACH_RELATIONSHIP):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
    case State.actionSucceeded(Type.SWITCH_ACCOUNT):
    case State.actionSucceeded(Type.CREATE_ACCOUNT):
      return initialState.cashiering.createACHRelationship;
    default:
      return state;
  }
};

export const unlinkACHRelationship = (state: any = initialState.cashiering.unlinkACHRelationship, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.CASHIERING_UNLINK_ACH_RELATIONSHIP):
      return clearedDefaults(state);
    case State.actionRequested(Type.CASHIERING_UNLINK_ACH_RELATIONSHIP):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.CASHIERING_UNLINK_ACH_RELATIONSHIP):
      return succeededReplacePayload(state, action);
    case State.actionFailed(Type.CASHIERING_UNLINK_ACH_RELATIONSHIP):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
    case State.actionSucceeded(Type.SWITCH_ACCOUNT):
    case State.actionSucceeded(Type.CREATE_ACCOUNT):
      return initialState.cashiering.unlinkACHRelationship;
    default:
      return state;
  }
};

export const editACHRelationship = (state: any = initialState.cashiering.editACHRelationship, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.CASHIERING_EDIT_NICKNAME):
      return clearedDefaults(state);
    case State.actionRequested(Type.CASHIERING_EDIT_NICKNAME):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.CASHIERING_EDIT_NICKNAME):
      return succeededReplacePayload(state, action);
    case State.actionFailed(Type.CASHIERING_EDIT_NICKNAME):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
    case State.actionSucceeded(Type.SWITCH_ACCOUNT):
    case State.actionSucceeded(Type.CREATE_ACCOUNT):
      return initialState.cashiering.editACHRelationship;
    default:
      return state;
  }
};

export const plaidLinkToken = (state: any = initialState.cashiering.plaidLinkToken, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.CASHIERING_GET_PLAID_LIK_TOKEN):
      return clearedDefaults(state);
    case State.actionRequested(Type.CASHIERING_GET_PLAID_LIK_TOKEN):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.CASHIERING_GET_PLAID_LIK_TOKEN):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.CASHIERING_GET_PLAID_LIK_TOKEN):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
    case State.actionSucceeded(Type.SWITCH_ACCOUNT):
    case State.actionSucceeded(Type.CREATE_ACCOUNT):
      return initialState.cashiering.plaidLinkToken;
    default:
      return state;
  }
};

export const transfers = (state: any = initialState.cashiering.transfers, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.CASHIERING_GET_TRANSFERS):
      return clearedDefaults(state);
    case State.actionRequested(Type.CASHIERING_GET_TRANSFERS):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.CASHIERING_GET_TRANSFERS):
      return succeededReplacePayload(state, action);
    case State.actionFailed(Type.CASHIERING_GET_TRANSFERS):
    case State.actionFailed(Type.CASHIERING_DEPOSIT_FUNDS):
    case State.actionFailed(Type.CASHIERING_WITHDRAW_FUNDS):
    case State.actionFailed(Type.CASHIERING_CANCEL_DEPOSIT):
    case State.actionFailed(Type.CASHIERING_CANCEL_WITHDRAW):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
    case State.actionSucceeded(Type.SWITCH_ACCOUNT):
    case State.actionSucceeded(Type.CREATE_ACCOUNT):
      return initialState.cashiering.transfers;
    default:
      return state;
  }
};

export const transferDetails = (state: any = initialState.cashiering.transferDetails, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.CASHIERING_GET_TRANSACTION_DETAILS):
      return clearedDefaults(state);
    case State.actionRequested(Type.CASHIERING_GET_TRANSACTION_DETAILS):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.CASHIERING_GET_TRANSACTION_DETAILS):
      return succeededReplacePayload(state, action);
    case State.actionFailed(Type.CASHIERING_GET_TRANSACTION_DETAILS):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
    case State.actionSucceeded(Type.SWITCH_ACCOUNT):
    case State.actionSucceeded(Type.CREATE_ACCOUNT):
      return initialState.cashiering.transferDetails;
    default:
      return state;
  }
};

export default combineReducers({
  achRelationships,
  createACHRelationship,
  unlinkACHRelationship,
  editACHRelationship,
  plaidLinkToken,
  transfers,
  transferDetails,
});
