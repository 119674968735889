import { useEffect, useRef, useState } from 'react';

import { cx } from '@emotion/css';
import { Col } from 'antd';
import { Formik, FormikProps } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import {
  doClearGetAccountWithdrawalFundsBalance,
  doGetAccountWithdrawalFundsBalance,
  withdrawFunds,
} from 'src/actions';
import { FilterBarConstant, DefinitionConstant, OrderConstant, TransferConstant } from 'src/constants';
import { GetAccountWithdrawalFundsBalanceResponseDto, OfferOrderStatusDto } from 'src/dtos';
import { useAccountSelector, useAccountBalance, useOfferOrders, useOrders } from 'src/hooks';
import { MFormCurrencyField, MFormCurrencyInput, MFormTextField, MAlert, MModal, UnknownErrorMessage } from 'src/lib';
import { OrderStatusLabel, OfferOrderStatusLabel } from 'src/models';
import { Display, FlexDirection, Images, Spacing } from 'src/styles';
import { getCurrencyFormatter, Maybe } from 'src/utils';
import * as Yup from 'yup';

import Spinner from '../../../../lib/Miscellaneous/Spinner';
import * as Styles from '../../NewTransfer.styles';
import { NewTransferConfirmStep } from '../NewTransferConfirmStep/NewTransferConfirmStep';

const formValidationSchema = Yup.object().shape({
  amount: Yup.number()
    .typeError('Amount must be a number')
    .required('Amount is required')
    .positive('Amount must be a positive number')
    .max(
      TransferConstant.MAX_AMOUNT,
      `The maximum transfer amount is ${getCurrencyFormatter().format(TransferConstant.MAX_AMOUNT)}`,
    ),
  hasAvailableFunds: Yup.bool().oneOf([true]),
});

export interface NewWithdrawalFormValues {
  amount: string;
  hasAvailableFunds: boolean;
}

export interface NewWithdrawalModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedBankAccount?: any;
}

const initialFormValues: NewWithdrawalFormValues = {
  amount: '',
  hasAvailableFunds: true,
};

export const NewWithdrawalModal = ({ isOpen, onClose, selectedBankAccount }: NewWithdrawalModalProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [step, setStep] = useState<'withdrawal' | 'confirm'>('withdrawal');
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<NewWithdrawalFormValues | null>(null);
  const formRef = useRef<FormikProps<NewWithdrawalFormValues> | null>(null);
  const [height, setHeight] = useState<number>(400);

  const withdrawalFundsBalance: Maybe<GetAccountWithdrawalFundsBalanceResponseDto> = useSelector(
    (state: any) => state.accountDetails.withdrawalFunds?.data,
  );
  const iswithdrawalFundsBalanceLoading: boolean = useSelector((state: any) =>
    Boolean(state.accountDetails.withdrawalFunds?.isLoading),
  );
  const iswithdrawalFundsBalanceError: boolean = useSelector((state: any) =>
    Boolean(state.accountDetails.withdrawalFunds?.__failed),
  );
  const withdrawalFundsBalanceError: string | undefined = useSelector(
    (state: any) => state.accountDetails.withdrawalFunds?.message,
  );

  const { account } = useAccountSelector();
  const {
    isAccountBalanceLoading,
    hasAccountBalanceError,
    accountBalanceError,
    cashAvailable,
    pendingOfferOrdersTotal,
    pendingExternalOfferOrdersTotal,
  } = useAccountBalance();
  const { offerOrderList, isOfferOrderListLoading } = useOfferOrders();
  const { orderList, isOrderListLoading } = useOrders(); // TODO: fetch only open orders from the server side (eliminate the need for filtering uncancelled orders)

  const uncancelledOfferOrders = offerOrderList.filter(anOfferOrder =>
    [
      OfferOrderStatusDto.PendingFunds,
      OfferOrderStatusDto.PendingAction,
      OfferOrderStatusDto.PendingOfferClose,
    ].includes(anOfferOrder.status.value),
  );

  const uncancelledBuyOrders = orderList.filter(
    anOrder => OrderConstant.UNCANCELLED_STATUS_LIST.includes(anOrder.status.value) && anOrder.action.isBuy,
  );

  const hasAnyRequestErrors = hasAccountBalanceError || iswithdrawalFundsBalanceError;

  const findCashAvailableForWithdrawal = () => {
    if (withdrawalFundsBalance) {
      return (
        withdrawalFundsBalance.availableFundsForWithdrawal - pendingOfferOrdersTotal + pendingExternalOfferOrdersTotal
      );
    }

    return 0;
  };

  const hasAnyUncancelledBuyOrders = () => uncancelledOfferOrders.length > 0 || uncancelledBuyOrders.length > 0;

  const hasAvailableFunds = (amount: string) => {
    if (isNaN(Number(amount))) {
      return false;
    }

    if (findCashAvailableForWithdrawal() < Number(amount)) {
      return false;
    }

    return true;
  };

  const updateHeightOnAmountChange = (hasAvailableFunds: boolean) => {
    if (hasAvailableFunds && !hasAnyRequestErrors) {
      setHeight(450);

      return;
    }

    setHeight(675);
  };

  const renderHasUncancelledOfferOrdersValidationMessage = (
    form: FormikProps<NewWithdrawalFormValues>,
  ): JSX.Element | null => {
    if (!form.values.hasAvailableFunds && uncancelledOfferOrders.length > 0) {
      return (
        <MAlert
          type='error'
          description={
            <p>
              You have open Offer Order(s), please click{' '}
              <a
                onClick={() => {
                  navigate(
                    `/main/history?show=${JSON.stringify([
                      FilterBarConstant.ShowFilterCategory.OfferOrder,
                    ])}&filterBy=${JSON.stringify([
                      `${OfferOrderStatusLabel.PendingFunds}_${FilterBarConstant.ByFilterCategory.OfferOrder}`,
                      `${OfferOrderStatusLabel.PendingOfferClose}_${FilterBarConstant.ByFilterCategory.OfferOrder}`,
                      `${OfferOrderStatusLabel.PendingAction}_${FilterBarConstant.ByFilterCategory.OfferOrder}`,
                    ])}`,
                  );
                }}>
                here{' '}
              </a>
              if you would like to view and/or attempt to cancel any order(s) which might give you sufficient funds to
              proceed with your cash withdrawal.
            </p>
          }
        />
      );
    }

    return null;
  };

  const renderHasUncancelledOrdersValidationMessage = (
    form: FormikProps<NewWithdrawalFormValues>,
  ): JSX.Element | null => {
    if (!form.values.hasAvailableFunds && uncancelledBuyOrders.length > 0) {
      return (
        <MAlert
          type='error'
          description={
            <p>
              You have open Security Buy Order(s), please click{' '}
              <a
                onClick={() => {
                  navigate(
                    `/main/history?show=${JSON.stringify([
                      FilterBarConstant.ShowFilterCategory.BuyOrder,
                    ])}&filterBy=${JSON.stringify([
                      `${OrderStatusLabel.PROCESSING}_${FilterBarConstant.ByFilterCategory.Order}`,
                      `${OrderStatusLabel.PENDING}_${FilterBarConstant.ByFilterCategory.Order}`,
                    ])}`,
                  );
                }}>
                here{' '}
              </a>
              if you would like to view and/or attempt to cancel any order(s) which might give you sufficient funds to
              proceed with your cash withdrawal.
            </p>
          }
        />
      );
    }

    return null;
  };

  const renderHasNoAvailableFundsValidationMessage = (
    form: FormikProps<NewWithdrawalFormValues>,
  ): JSX.Element | null => {
    if (!form.values.hasAvailableFunds && hasAnyUncancelledBuyOrders()) {
      return (
        <>
          {renderHasUncancelledOfferOrdersValidationMessage(form)}
          {renderHasUncancelledOrdersValidationMessage(form)}
        </>
      );
    }

    if (!form.values.hasAvailableFunds) {
      return <MAlert description={'You do not have enough available funds to process this request.'} type='error' />;
    }

    return null;
  };

  const renderWithdrawalStep = () => {
    if (isOfferOrderListLoading || isOrderListLoading || isAccountBalanceLoading || iswithdrawalFundsBalanceLoading) {
      return <Spinner customSpinnerStyle={Styles.spinner(height)} />;
    }

    return (
      <Formik
        enableReinitialize
        validate={async values => {
          try {
            await formValidationSchema.validate(values);
            setIsFormValid(true);
          } catch (error: any) {
            setIsFormValid(false);

            return { [error.path]: error.errors };
          }
        }}
        initialValues={formValues ?? initialFormValues}
        innerRef={form => (formRef.current = form)}
        onSubmit={values => {
          setFormValues(values);
        }}>
        {form => {
          return (
            <>
              <Col span={24}>
                <MFormCurrencyField
                  label='Cash Available'
                  value={cashAvailable}
                  tooltip={{
                    type: 'popover',
                    content: DefinitionConstant.CASH_AVAILABLE,
                    icon: 'question',
                  }}
                />
              </Col>

              <Col span={24}>
                <MFormCurrencyField
                  label='Funds Available For Withdrawal'
                  value={findCashAvailableForWithdrawal()}
                  tooltip={{
                    type: 'popover',
                    content: DefinitionConstant.AVAILABLE_FUNDS_FOR_WITHDRAWAL,
                    icon: 'question',
                  }}
                />
              </Col>

              <Col span={24}>
                <MFormTextField
                  label={
                    <div>
                      <div className={Styles.senderReceiverTopLabel}>FROM</div>
                      <div>My IPO</div>
                    </div>
                  }
                  value={
                    <Col span={24} className={cx(Display.flex, FlexDirection.column)}>
                      <div>&nbsp;</div>
                      <div>{account?.accountNumber}</div>
                    </Col>
                  }
                />
              </Col>

              <Col span={24}>
                <MFormTextField
                  label={
                    <Col span={24} className={cx(Display.flex, FlexDirection.column)}>
                      <div className={Styles.senderReceiverTopLabel}>TO</div>
                      <div>
                        {selectedBankAccount.tradingBlockACHRelationship.bankAccountType}
                        <span
                          className={
                            Styles.nickname
                          }>{` (${selectedBankAccount.tradingBlockACHRelationship.nickName})`}</span>
                      </div>
                    </Col>
                  }
                  value={
                    <Col span={24} className={cx(Display.flex, FlexDirection.column)}>
                      <div>
                        {selectedBankAccount.bankName}
                        <img
                          alt='bankLogo'
                          src={
                            selectedBankAccount?.tradingBlockPlaidInstitution?.logo
                              ? `data:image/jpeg;base64,${selectedBankAccount?.tradingBlockPlaidInstitution?.logo}`
                              : Images.BankPlaceholder
                          }
                          className={Styles.bankLogo}
                        />
                      </div>
                      <div>{selectedBankAccount.tradingBlockACHRelationship.bankAccount}</div>
                    </Col>
                  }
                />
              </Col>

              <Col span={24} className={Spacing.my12}>
                <MFormCurrencyInput
                  label='Withdrawal Amount'
                  value={form.values.amount}
                  onChange={value => {
                    form.setFieldValue('amount', value);
                    const hasFunds = hasAvailableFunds(value);
                    form.setFieldValue('hasAvailableFunds', hasFunds);
                    updateHeightOnAmountChange(hasFunds);
                  }}
                  error={form.errors.amount}
                  testId={'money-transfer-withdrawal'}
                />
              </Col>

              <Col span={24} className={cx(Display.flex, FlexDirection.column)}>
                {renderHasNoAvailableFundsValidationMessage(form)}
                {hasAccountBalanceError && (
                  <MAlert type='error' description={<UnknownErrorMessage message={accountBalanceError} />} />
                )}
                {iswithdrawalFundsBalanceError && (
                  <MAlert type='error' description={<UnknownErrorMessage message={withdrawalFundsBalanceError} />} />
                )}
              </Col>
            </>
          );
        }}
      </Formik>
    );
  };

  const renderConfirmStep = () => {
    return (
      <NewTransferConfirmStep
        type='withdrawal'
        amount={formValues?.amount ?? ''}
        selectedBankAccount={selectedBankAccount}
        accountNumber={account?.accountNumber}
      />
    );
  };

  const renderContent = () => {
    if (step === 'withdrawal') {
      return renderWithdrawalStep();
    }

    return renderConfirmStep();
  };

  useEffect(() => {
    if (hasAccountBalanceError || iswithdrawalFundsBalanceError) {
      setHeight(675);

      return;
    }
  }, [hasAccountBalanceError, iswithdrawalFundsBalanceError]);

  useEffect(() => {
    if (account?.accountId) {
      dispatch(doGetAccountWithdrawalFundsBalance({ params: { accountId: account?.accountId } }));
    }

    return () => {
      dispatch(doClearGetAccountWithdrawalFundsBalance());
    };
  }, [account?.accountId]);

  return (
    <MModal
      customWidth={750}
      customHeight={height}
      visible={isOpen}
      title='New Withdrawal'
      primaryButtonText={step === 'withdrawal' ? 'Continue' : 'Transfer'}
      secondaryButtonText={step === 'withdrawal' ? undefined : 'Back'}
      tertiaryButtonText='Close'
      onPrimaryButtonClick={async () => {
        if (step === 'withdrawal') {
          formRef.current?.submitForm();
          setHeight(475);
          setStep('confirm');
        }

        if (step === 'confirm' && formValues) {
          onClose();
          dispatch(
            withdrawFunds(selectedBankAccount.tradingBlockACHRelationship.accountId, {
              DisbursementType: 'PartialBalance',
              RequestedAmount: formValues.amount,
              RelationshipId: selectedBankAccount.tradingBlockACHRelationship.id,
            }),
          );
        }
      }}
      onSecondaryButtonClick={() => {
        setStep('withdrawal');
        setHeight(400);
      }}
      onTertiaryButtonClick={onClose}
      isDisabledPrimaryButton={!isFormValid || hasAnyRequestErrors}
      onClose={onClose}>
      {renderContent()}
    </MModal>
  );
};
