import { useEffect } from 'react';

import { cx } from '@emotion/css';
import { Col, Row } from 'antd';
import { isUndefined } from 'lodash';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router';
import { useAccountSelector } from 'src/hooks';
import { MButton } from 'src/lib';
import { BaseStyle, ScreenBreakpoint, Spacing } from 'src/styles';
import { isAccountTrustedContactComplete } from 'src/utils';

import { MyAccountLayout } from '../../../lib/Layout/MyAccountLayout/MyAccountLayout';
import { MyAccountSidebarMainMenuItemKey } from '../../../lib/Layout/MyAccountLayout/MyAccountSidebar';

import { AccountBeneficiariesSection } from './AccountBeneficiariesSection';
import { JointAccountInterestSection } from './JointAccountInterestSection';
import { TrustedContactSection } from './TrustedContactSection';

export const TrustedContact = () => {
  const navigate = useNavigate();

  const isCreateSuccess: boolean = useSelector((state: any) =>
    Boolean(state.accountHolders.createTrustedContact.__succeeded),
  );
  const isPatchSuccess: boolean = useSelector((state: any) =>
    Boolean(state.accountHolders.patchTrustedContact.__succeeded),
  );
  const isPatchJointInterestSuccess: boolean = useSelector((state: any) =>
    Boolean(state.accounts.patchJointInterest.__succeeded),
  );

  const { account } = useAccountSelector();

  const isMobile = useMediaQuery({ query: `(max-width: ${ScreenBreakpoint.mobile.max})` });

  const hasAnyUpsertRequest = () => {
    if (account?.type.isJoint && account?.jointAccountType?.isTenantsInCommon) {
      return (isCreateSuccess || isPatchSuccess) && isPatchJointInterestSuccess;
    }

    return isCreateSuccess || isPatchSuccess;
  };

  const getPageTitle = () => {
    if (isMobile) {
      return account?.type.isJoint
        ? 'Tenants in Common'
        : account?.type.isRetirement
        ? 'Trusted Contact (Optional)'
        : undefined;
    }

    return account?.type.isJoint && account?.jointAccountType?.isTenantsInCommon
      ? 'Tenants in Common'
      : 'Trusted Contact (Optional)';
  };

  const shouldSendAnalyticsEvent = () => {
    if (account?.type.isJoint && account?.jointAccountType?.isTenantsInCommon) {
      return isCreateSuccess && isPatchJointInterestSuccess;
    }

    return isCreateSuccess;
  };

  const canContinue = () => {
    if (account?.type.isJoint && account.jointAccountType?.isTenantsInCommon) {
      return (
        !isUndefined(account.primaryAccountHolder?.jointTenantsInCommonInterestPercent) &&
        !isUndefined(account.secondaryAccountHolder?.jointTenantsInCommonInterestPercent)
      );
    }

    return true;
  };

  const shouldRedirectToSuitabilityInformation = () =>
    !account?.accountId && hasAnyUpsertRequest() && isAccountTrustedContactComplete(account);

  useEffect(() => {
    if (shouldSendAnalyticsEvent()) {
      window.gtag('event', 'account_trusted_contact_complete');
    }
  }, [isCreateSuccess, isPatchJointInterestSuccess]);

  useEffect(() => {
    if (shouldRedirectToSuitabilityInformation()) {
      navigate(`/accounts/suitability-information`);
    }
  }, [isCreateSuccess, isPatchSuccess, isPatchJointInterestSuccess]);

  return (
    <MyAccountLayout
      sidebarMenuItemKey={MyAccountSidebarMainMenuItemKey.TrustedContact}
      title={getPageTitle()}
      testId={'tenants-in-common'}>
      <JointAccountInterestSection />
      <TrustedContactSection />
      <AccountBeneficiariesSection />
      {account?.accountId ? null : (
        <Row>
          <Col span={24} className={cx(BaseStyle.level, Spacing.my32)}>
            <MButton onClick={() => navigate(`/accounts/suitability-information`)} disabled={!canContinue()}>
              Continue
            </MButton>
          </Col>
        </Row>
      )}
    </MyAccountLayout>
  );
};
