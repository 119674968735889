import { Fragment, useEffect, useState } from 'react';

import { Card, Col, Divider, Row, Tabs } from 'antd';
import Meta from 'antd/lib/card/Meta';
import Parser from 'html-react-parser';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Params, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  doClearOfferDetails,
  getOfferDetails,
  offerOrdersGetListRequested,
  offerWatchesDeleteRequested,
  redirectToOfferAdd,
  redirectToOfferOrderAdd,
} from 'src/actions';
import { OfferWatchDto } from 'src/dtos';
import { useAccountSelector, useAuthenticatedUserSelector } from 'src/hooks';
import {
  ClosedOffersTag,
  ConfirmActionModal,
  ExternalOfferDisclaimerModal,
  MAlert,
  MTag,
  WarningAlertDescription,
  WhatsAccreditedInvestorModal,
} from 'src/lib';
import { OfferDetails, OfferOrder } from 'src/models';
import { Color, Images } from 'src/styles';
import { findOfferClosedDate, isOfferClosed, isRegDTypeCOffer, shouldDisplayTotalCapitalRaised } from 'src/utils';

import { OfferOrderConstant } from '../../../constants/offerOrders';
import { useOfferOrders } from '../../../hooks/useOfferOrders';
import { useOfferWatches } from '../../../hooks/useOfferWatches';
import { MButton } from '../../../lib/FormComponents/MButton/MButton';
import { OFFER_ORDERS_PATH } from '../../../lib/Guards/NewOfferOrderGuard';
import { BacklinkButton } from '../../../lib/Miscellaneous/BacklinkButton/BacklinkButton';
import CurrencyField from '../../../lib/Miscellaneous/FormattedFields/CurrencyField/CurrencyField';
import Spinner from '../../../lib/Miscellaneous/Spinner';
import TooltipComp from '../../../lib/Miscellaneous/Tooltip';
import Video from '../../../lib/Miscellaneous/Video';
import { RemainingOfferDaysTag } from '../../../lib/RemainingOfferDaysTag/RemainingOfferDaysTag';
import { MAIN_PATH } from '../../Main';
import { HISTORY_PATH } from '../../Main/pages/History/History';
import { SUMMARY_PATH } from '../../Main/pages/Summary/Summary';
import { OfferDescriptionTab } from '../components/OfferDescriptionTab/OfferDescriptionTab';
import { OfferDetailsTab } from '../components/OfferDetailsTab/OfferDetailsTab';
import { OfferDocumentsTab } from '../components/OfferDocumentsTab/OfferDocumentsTab';

import { NewOfferWatchModal } from './NewOfferWatchModal';
import * as Styles from './OfferDetailsPage.styles';

const { TabPane } = Tabs;

export const OfferDetailsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { offerId }: Readonly<Params<string>> = useParams();
  const offerDetails: OfferDetails = useSelector((state: any) => state.offers.offerDetails?.data);
  const loadingOfferDetails: boolean = useSelector((state: any) => state.offers.offerDetails?.isLoading);

  const [isNewOfferWatchModalVisible, setIsNewOfferWatchModalVisible] = useState(false);
  const [isCancelOfferWatchModalVisible, setIsCancelOfferWatchModalVisible] = useState(false);
  const [pendingOfferOrdersTotal, setPendingOfferOrdersTotal] = useState(0);
  const [isAllowedPostCloseOfferInvestment, setIsAllowedPostCloseOfferInvestment] = useState<boolean>(false);
  const [watchedOffer, setWatchedOffer] = useState<OfferWatchDto>();
  const [isExternalOfferDisclaimerModalVisible, setIsExternalOfferDisclaimerModalVisible] = useState<boolean>(false);
  const [isWhatsAccreditedInvestorOpen, setIsWhatsAccreditedInvestorOpen] = useState<boolean>(false);

  const { user } = useAuthenticatedUserSelector();
  const { account } = useAccountSelector();
  const { offerOrderList } = useOfferOrders();
  const { offerWatchList } = useOfferWatches();

  const pendingOfferOrders = offerOrderList.filter(anOfferOrder =>
    OfferOrderConstant.PENDING_STATUSES.includes(anOfferOrder.status.value),
  );

  const canUserInvest = () => {
    if (offerDetails?.isExternalOffer && account?.type.isRetirement) {
      return false;
    }

    if (isOfferClosed(offerDetails) && isAllowedPostCloseOfferInvestment) {
      return true;
    }

    if (!isOfferClosed(offerDetails) && !offerDetails?.isComingSoon) {
      return true;
    }
  };

  const onInvestClick = () => {
    window.gtag('event', 'offer_invest', {
      offer_name: offerDetails.name,
      offer_id: offerDetails.id,
      account_id: account?.accountId,
    });

    if (!user) {
      dispatch(redirectToOfferOrderAdd(offerDetails.id));

      return navigate('/register');
    }

    if (user && !account?.accountId) {
      dispatch(redirectToOfferOrderAdd(offerDetails.id));

      return navigate(`/${MAIN_PATH}/${SUMMARY_PATH}`);
    }

    return navigate(`/${MAIN_PATH}/${OFFER_ORDERS_PATH}/${offerDetails.id}/orders/new`);
  };

  const onCancelOfferWatch = () => {
    setIsCancelOfferWatchModalVisible(true);
  };

  const onNewOfferWatch = () => {
    if (!user) {
      dispatch(redirectToOfferAdd(offerId));

      return navigate('/register');
    }

    setIsNewOfferWatchModalVisible(true);
  };

  const renderCoverCard = () => {
    if (!offerDetails) return;

    if (offerDetails?.assets?.[0]?.type === 'video')
      return (
        <Video
          videoUrl={offerDetails?.assets[0]?.url}
          imageUrl={offerDetails?.videoThumbnailUrl || Images.DefaultOffering}
          playBtnSize={'large'}
        />
      );

    return (
      <img
        alt='thumbnail'
        className={Styles.featuredOfferImage}
        src={offerDetails?.assets?.[0]?.url || Images.DefaultOffering}
      />
    );
  };

  const renderMetaCard = () => {
    if (!offerDetails) return;

    return (
      <Fragment>
        <Row
          gutter={[
            { xs: 10, sm: 12, md: 16 },
            { xs: 10, sm: 12, md: 16 },
          ]}>
          <Col xs={6} className={Styles.offerLogoWrapper}>
            <img alt='offer_logo' className={Styles.offerLogo} src={offerDetails?.logoUrl || Images.DefaultOffering} />
          </Col>
          <Col xs={18}>
            <Row>
              <Col>
                <span className={Styles.featuredOfferName} data-testid={'offer-txt-title'}>
                  {offerDetails?.name}
                </span>
              </Col>
            </Row>
            <Row
              gutter={[
                { xs: 5, sm: 6, md: 8 },
                { xs: 5, sm: 6, md: 8 },
              ]}>
              {isOfferClosed(offerDetails) && (
                <Col>
                  <MTag type='red' closable={false}>
                    *Closed on {findOfferClosedDate(offerDetails) ?? '-'}
                  </MTag>
                </Col>
              )}
              {!isOfferClosed(offerDetails) && (
                <>
                  {offerDetails?.featured && (
                    <Col>
                      <MTag type='blue'>Featured</MTag>
                    </Col>
                  )}
                  <Col>
                    <RemainingOfferDaysTag offer={offerDetails} />
                  </Col>
                  {(offerDetails?.isComingSoon || offerDetails?.new) && (
                    <Col>
                      <MTag type='lightblue' closable={false}>
                        {offerDetails?.isComingSoon ? 'Coming Soon' : 'New'}
                      </MTag>
                    </Col>
                  )}
                  {isRegDTypeCOffer(offerDetails) && (
                    <Col>
                      <MTag
                        type='gray'
                        closable={false}
                        className={Styles.accreditedInvestorsOnly}
                        onClick={() => setIsWhatsAccreditedInvestorOpen(true)}>
                        <span className={Styles.accreditedInvestorsOnlyText}>Accredited Investors Only</span>{' '}
                        <i className='ri-information-line' />
                      </MTag>
                    </Col>
                  )}
                  {offerDetails?.isComingSoon && watchedOffer && (
                    <Col>
                      <MTag type='lightgreen' icon={<i className={`ri-heart-2-line ${Styles.indicationLogo}`} />}>
                        Interest Indicated
                      </MTag>
                    </Col>
                  )}
                </>
              )}
              {pendingOfferOrdersTotal > 0 && (
                <Col>
                  <MTag type='orange' icon={<i className='ri-time-line' />}>
                    Pending Order
                  </MTag>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
        <Row style={{ marginTop: '1rem' }}>
          {offerDetails?.isExternalOffer && (
            <Col span={24}>
              <MAlert
                type='warning'
                showIcon
                icon={<i className='ri-funds-fill' />}
                description={
                  <WarningAlertDescription
                    text='This Offering is held & funded outside of My IPO.'
                    onClick={() => {
                      setIsExternalOfferDisclaimerModalVisible(true);
                    }}
                    testId={'offer-order-details-warning-alert'}
                  />
                }
              />
            </Col>
          )}
          {offerDetails?.isComingSoon && watchedOffer && (
            <Col span={24}>
              <MAlert
                type='success'
                showIcon
                description={
                  <>
                    You have previously indicated an interest ranging in {watchedOffer.investmentRange} as the desired
                    investment amount. You will receive updates and notifications when the Offer becomes for actual
                    investment, so you can in turn place an actual order for this Offer.
                  </>
                }
                className={Styles.offerWatchAlert}
              />
            </Col>
          )}
          {pendingOfferOrdersTotal > 0 && (
            <Col span={24}>
              <MAlert
                type='warning'
                showIcon
                description={
                  <>
                    You currently have{' '}
                    <span style={{ fontWeight: 'bold' }}>{pendingOfferOrdersTotal} pending order(s) </span>
                    for this Offer. If you want to track their status, edit or cancel (if eligible) any of the order(s)
                    you have placed, please go to the{' '}
                    <Link to={`/${MAIN_PATH}/${HISTORY_PATH}`} className={Styles.goToSummary}>
                      History page.
                    </Link>
                  </>
                }
                className={Styles.pendingOfferOrdersAlert}
              />
            </Col>
          )}
        </Row>
        <Divider className={Styles.divider} />
        <Row
          gutter={[
            { xs: 10, sm: 12, md: 16 },
            { xs: 10, sm: 12, md: 16 },
          ]}>
          {offerDetails?.yield && !offerDetails.nonVisibleAppFields?.includes('yield') && (
            <Col style={{ color: Color.ORANGE.ORANGE9 }} className={Styles.offerDetailsLabel}>
              {`${offerDetails?.yield}`}

              <div>
                <span className={Styles.annualYieldLabel}>Annual Yield</span>
                <TooltipComp text={'Yield is a return on an investment in the form of interest or dividends.'} />
              </div>
            </Col>
          )}
          {shouldDisplayTotalCapitalRaised(offerDetails) ? (
            <Col style={{ color: Color.GRAYSCALE.GRAY9 }} className={Styles.offerDetailsLabel}>
              <CurrencyField value={offerDetails?.totalCapitalRaised} />
              <div className={Styles.minimumInvestmentLabel}>Total Capital Raised</div>
            </Col>
          ) : (
            <Col style={{ color: Color.GRAYSCALE.GRAY9 }} className={Styles.offerDetailsLabel}>
              <CurrencyField value={offerDetails?.minimumInvestCurrencyAmt} />
              <div className={Styles.minimumInvestmentLabel}>Minimum Investment</div>
            </Col>
          )}
          <Col flex={'auto'} className={Styles.actionButton}>
            {canUserInvest() && (
              <MButton onClick={onInvestClick} testId={'offer-btn-invest'}>
                Invest
              </MButton>
            )}
            {!isOfferClosed(offerDetails) && offerDetails?.isComingSoon && (
              <MButton
                onClick={watchedOffer ? onCancelOfferWatch : onNewOfferWatch}
                type={watchedOffer ? 'secondary' : 'primary'}>
                {watchedOffer ? 'Cancel Indication' : 'Indicate Interest'}
              </MButton>
            )}
          </Col>
          {isOfferClosed(offerDetails) && (
            <Col span={24}>
              <div className={Styles.featuredOfferSubtextOnClosed}>{Parser(offerDetails?.subtextOnClosed || '')}</div>
            </Col>
          )}
        </Row>
      </Fragment>
    );
  };

  useEffect(() => {
    if (user && offerDetails) {
      setIsAllowedPostCloseOfferInvestment(user.postcloseOffers.some(anOfferId => anOfferId === offerDetails.id));
    } else {
      setIsAllowedPostCloseOfferInvestment(false);
    }
  }, [user, offerDetails]);

  useEffect(() => {
    return () => {
      dispatch(doClearOfferDetails());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!offerDetails && !loadingOfferDetails && offerId) {
      dispatch(getOfferDetails(offerId));
    }
  }, [dispatch, offerDetails, loadingOfferDetails, offerId]);

  useEffect(() => {
    setWatchedOffer(offerWatchList.find(anOfferWatch => anOfferWatch.offerId === offerDetails?.id));
  }, [offerWatchList, offerDetails?.id]);

  useEffect(() => {
    const offerPendingOrders = pendingOfferOrders.filter((item: OfferOrder) => item.offerId === offerDetails?.id);
    setPendingOfferOrdersTotal(offerPendingOrders.length);
  }, [offerOrderList, offerDetails]);

  useEffect(() => {
    if (location.state)
      dispatch(offerOrdersGetListRequested({ queryParams: { filterBy: { accountId: account?.accountId } } }));
  }, [location]);

  if (loadingOfferDetails || _.isEmpty(offerDetails)) return <Spinner />;

  return (
    <Fragment>
      <Row className={Styles.mainContainer} gutter={[, { xs: 16, sm: 24, md: 32 }]}>
        <Col span={24}>
          <BacklinkButton to='/offers' label='All Offers' />
        </Col>
        <Col span={24}>
          <Card className={Styles.cardWrapper} cover={renderCoverCard()}>
            <Meta title={renderMetaCard()} />
          </Card>
        </Col>
        <Col span={24}>
          <div className={Styles.cardWrapper}>
            <Col className={Styles.tabsContainer}>
              <Tabs defaultActiveKey='1'>
                <TabPane tab='Details' key='1'>
                  <OfferDetailsTab offering={offerDetails} />
                </TabPane>
                <TabPane tab='Description' key='2'>
                  <OfferDescriptionTab offering={offerDetails} />
                </TabPane>
                <TabPane tab='Documents' key='3'>
                  <OfferDocumentsTab offerDocuments={offerDetails.documents} />
                </TabPane>
              </Tabs>
            </Col>
          </div>
        </Col>
        <Col span={24}>
          <div className={Styles.disclaimerStyle}>{Parser(offerDetails?.disclaimer)}</div>

          {isOfferClosed(offerDetails) && <ClosedOffersTag />}
        </Col>
      </Row>
      {offerDetails.isExternalOffer && (
        <ExternalOfferDisclaimerModal
          isLoading={loadingOfferDetails}
          isOpen={isExternalOfferDisclaimerModalVisible}
          onClose={() => {
            setIsExternalOfferDisclaimerModalVisible(false);
          }}
          disclaimer={offerDetails.externalDetails?.disclaimer}
        />
      )}
      <WhatsAccreditedInvestorModal
        isOpen={isWhatsAccreditedInvestorOpen}
        onClose={() => setIsWhatsAccreditedInvestorOpen(false)}
      />
      <NewOfferWatchModal
        offer={offerDetails}
        visible={isNewOfferWatchModalVisible}
        onClose={() => setIsNewOfferWatchModalVisible(false)}
      />
      <ConfirmActionModal
        title='Cancel Offer Interest'
        isOpen={isCancelOfferWatchModalVisible}
        message={`Are you sure you want to stop receiving updates and notifications for "${offerDetails.name}" offer?`}
        onOk={() => {
          if (watchedOffer) {
            dispatch(offerWatchesDeleteRequested(watchedOffer?.id));
            setIsCancelOfferWatchModalVisible(false);
          }
        }}
        onCancel={() => setIsCancelOfferWatchModalVisible(false)}
      />
    </Fragment>
  );
};
