import { call, put, SagaReturnType, select, takeEvery } from 'redux-saga/effects';
import { doSucceededAccountBalance, doSucceededGetAccountWithdrawalFundsBalance } from 'src/actions';
import { BalanceApi, getClientApi } from 'src/data-communication';
import { GetAccountWithdrawalFundsBalanceDto } from 'src/dtos';
import { mapAccountBalanceDtoToModel } from 'src/mappers';

import { State, Type } from '../actions/utils';
import { TReduxAction } from '../typings/commonTypes';

import { safeSaga } from './utils';

export function* getAccountBalance(action: TReduxAction) {
  const { accountId } = action.payload;

  const { authToken } = yield select(state => state.auth.data);

  const response: SagaReturnType<BalanceApi['retrieve']> = yield call(getClientApi().balance.retrieve, {
    params: { accountId },
    authToken,
  });

  yield put(doSucceededAccountBalance(mapAccountBalanceDtoToModel(response)));
}

export function* getAccountWithdrawalFundsBalance(action: TReduxAction) {
  const dto: GetAccountWithdrawalFundsBalanceDto = action.payload;

  const { authToken } = yield select(state => state.auth.data);

  const response: SagaReturnType<BalanceApi['retrieveWithdrawalFunds']> = yield call(
    getClientApi().balance.retrieveWithdrawalFunds,
    {
      params: dto.params,
      authToken,
    },
  );

  yield put(doSucceededGetAccountWithdrawalFundsBalance(response));
}

/**
 * Account Details sagas
 */
export default function* accountDetailsSaga() {
  // ACCOUNT_BALANCE
  yield takeEvery(
    State.actionRequested(Type.ACCOUNTS_GET_BALANCE),
    safeSaga(getAccountBalance, Type.ACCOUNTS_GET_BALANCE),
  );
  yield takeEvery(
    State.actionRequested(Type.GET_ACCOUNT_WITHDRAWAL_FUNDS_BALANCE),
    safeSaga(getAccountWithdrawalFundsBalance, Type.GET_ACCOUNT_WITHDRAWAL_FUNDS_BALANCE, false),
  );
  yield takeEvery(Type.REFRESH_ACCOUNT_BALANCE, safeSaga(getAccountBalance, Type.REFRESH_ACCOUNT_BALANCE));
}

// NOTE: switch from beta to prod wss env
