import { ErrorCode } from 'src/constants';

export interface UnknownErrorMessageProps {
  code?: ErrorCode;
  message?: string;
}

export const UnknownErrorMessage = ({ code, message }: UnknownErrorMessageProps) => {
  if (code) {
    return <p>(Error code {code}). Please try again later or contact My IPO client services.</p>;
  }

  if (message) {
    return <p>Unknown error occurred (error: {message}). Please try again later or contact My IPO client services.</p>;
  }

  return <p>Unknown error occurred. Please try again later or contact My IPO client services.</p>;
};
