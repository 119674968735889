import { useEffect, useState } from 'react';

import { Col } from 'antd';
import { Formik, FormikProps } from 'formik';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  doClearCreateAccountHolderPhysicalAddress,
  doClearPatchAccountHolderPhysicalAddress,
  doCreateAccountHolderPhysicalAddress,
  doPatchAccountHolderPhysicalAddress,
} from 'src/actions';
import { ErrorName, US_COUNTRY_LABEL, US_COUNTRY_STATE_LIST, US_COUNTRY_VALUE } from 'src/constants';
import { MFormSaveButton, MFormInput, MFormSelect, MLinkButton, ErrorResponseAlert } from 'src/lib';
import { AccountHolder, AccountHolderAddress } from 'src/models';
import { Spacing } from 'src/styles';
import { assertNonNullable, getCountryOptionList } from 'src/utils';

import { upsertAccountHolderPhysicalAddressValidation } from './validations';

interface AccountHolderPhysicalAddressFormValues {
  address1?: string;
  address2?: string;
  country?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  citizenshipCountry?: string;
  birthCountry?: string;
}

export interface PhysicalAddressFormProps {
  accountUuid?: string;
  accountHolder?: AccountHolder;
  isPrimary: boolean;
  primaryAccountHolderAddress?: AccountHolderAddress;
  onCancel?: () => void;
  onSave?: () => void;
}

export const citizenshipOptions = [
  {
    label: 'U.S. Citizen',
    value: 'USA',
  },
];

export const countryOptions = [{ label: US_COUNTRY_LABEL, value: US_COUNTRY_VALUE }];

export const birthCountryOptions = getCountryOptionList();

export const PhysicalAddressForm = ({
  accountHolder,
  accountUuid,
  isPrimary,
  primaryAccountHolderAddress,
  onCancel,
  onSave,
}: PhysicalAddressFormProps) => {
  const dispatch = useDispatch();

  const isCreatePhysicalAddressLoading = useSelector((state: any) =>
    Boolean(state.accountHolders.createPhysicalAddress?.__requested),
  );
  const createError = useSelector((state: any) => state.accountHolders.createPhysicalAddress?.error);
  const succeededCreatePhysicalAddress = useSelector(
    (state: any) => state.accountHolders.createPhysicalAddress?.__succeeded,
  );

  const isPatchPhysicalAddressLoading = useSelector((state: any) =>
    Boolean(state.accountHolders.patchPhysicalAddress?.__requested),
  );
  const succeededPatchPhysicalAddress = useSelector(
    (state: any) => state.accountHolders.patchPhysicalAddress?.__succeeded,
  );
  const patchError = useSelector((state: any) => state.accountHolders.patchPhysicalAddress?.error);

  const [isAddressPopulated, setIsAddressPopulated] = useState<boolean>(false);

  const [shouldPatch, setShouldPatch] = useState<boolean>(false);

  const isPhysicalAddressAlreadySaved = () => !isEmpty(accountHolder?.physicalAddress);

  const createRequestData = useSelector((state: any) => state.accountHolders.createPhysicalAddress?.data);
  const patchRequestData = useSelector((state: any) => state.accountHolders.patchPhysicalAddress?.data);

  const isRequestMatched = () =>
    accountHolder?.id === createRequestData?.params?.accountHolderId ||
    accountHolder?.id === patchRequestData?.params?.accountHolderId;

  const isUpsertLoading = (isCreatePhysicalAddressLoading || isPatchPhysicalAddressLoading) && isRequestMatched();

  const _onCancel = (form: FormikProps<AccountHolderPhysicalAddressFormValues>) => {
    form.resetForm();

    if (onCancel) {
      onCancel();
    }
  };

  const _onSave = (form: FormikProps<AccountHolderPhysicalAddressFormValues>) => {
    form.submitForm();

    if (onSave) {
      onSave();
    }
  };

  useEffect(() => {
    if (isPhysicalAddressAlreadySaved()) {
      setShouldPatch(true);
    }
  }, [accountHolder]);

  useEffect(() => {
    if ((succeededCreatePhysicalAddress || succeededPatchPhysicalAddress) && isRequestMatched()) {
      if (onCancel) {
        onCancel();
      }
    }
  }, [succeededCreatePhysicalAddress, succeededPatchPhysicalAddress]);

  useEffect(() => {
    return () => {
      dispatch(doClearCreateAccountHolderPhysicalAddress());
      dispatch(doClearPatchAccountHolderPhysicalAddress());
    };
  }, []);

  return (
    <Formik<AccountHolderPhysicalAddressFormValues>
      validateOnChange
      initialValues={{
        address1: accountHolder?.physicalAddress?.address1,
        address2: accountHolder?.physicalAddress?.address2,
        city: accountHolder?.physicalAddress?.city,
        country: accountHolder?.physicalAddress?.country?.value ?? US_COUNTRY_VALUE,
        state: accountHolder?.physicalAddress?.state?.value,
        postalCode: accountHolder?.physicalAddress?.postalCode,
        citizenshipCountry: accountHolder?.citizenshipCountry?.value,
        birthCountry: accountHolder?.birthCountry?.value,
      }}
      validationSchema={upsertAccountHolderPhysicalAddressValidation}
      onSubmit={values => {
        assertNonNullable(accountHolder, 'AccountHolder');
        assertNonNullable(accountUuid, 'accountUuid');

        if (shouldPatch) {
          dispatch(
            doPatchAccountHolderPhysicalAddress({
              params: {
                id: accountUuid,
                accountHolderId: accountHolder.id,
              },
              body: {
                address1: values.address1,
                address2: values.address2,
                city: values.city,
                state: values.state,
                postalCode: values.postalCode,
                country: values.country,
                birthCountry:
                  values.birthCountry === accountHolder?.birthCountry?.value ? undefined : values.birthCountry,
              },
            }),
          );

          return;
        }
        dispatch(
          doCreateAccountHolderPhysicalAddress({
            params: {
              id: accountUuid,
              accountHolderId: accountHolder.id,
            },
            body: values,
          }),
        );
      }}>
      {form => {
        const onPopulatePhysicalAddress = () => {
          if (createError?.error === ErrorName.UspsAddressMismatch) {
            const message = JSON.parse(createError?.message);
            form.setFieldValue('address1', message.address.address1);
            form.setFieldValue('address2', message.address.address2);
            form.setFieldValue('city', message.address.city);
            form.setFieldValue('state', message.address.state);
            form.setFieldValue('postalCode', message.address.postalCode);
            setIsAddressPopulated(true);
          }

          if (patchError?.error === ErrorName.UspsAddressMismatch) {
            const message = JSON.parse(patchError?.message);
            form.setFieldValue('address1', message.address.address1);
            form.setFieldValue('address2', message.address.address2);
            form.setFieldValue('city', message.address.city);
            form.setFieldValue('state', message.address.state);
            form.setFieldValue('postalCode', message.address.postalCode);
            setIsAddressPopulated(true);
          }
        };

        const populatePhysicalAddress = async () => {
          if (primaryAccountHolderAddress) {
            form.setValues({
              address1: primaryAccountHolderAddress.address1,
              address2: primaryAccountHolderAddress.address2,
              city: primaryAccountHolderAddress.city,
              country: primaryAccountHolderAddress.country?.value,
              postalCode: primaryAccountHolderAddress.postalCode,
              state: primaryAccountHolderAddress.state?.value,
              birthCountry: form.values.birthCountry,
              citizenshipCountry: form.values.citizenshipCountry,
            });
          }
        };

        useEffect(() => {
          setIsAddressPopulated(false);
        }, [createError, patchError]);

        return (
          <>
            {!isPrimary && (
              <MLinkButton
                onClick={populatePhysicalAddress}
                disabled={isEmpty(primaryAccountHolderAddress)}
                underlined
                className={Spacing.my16}>
                Populate same address(es) as primary account holder
              </MLinkButton>
            )}
            <Col span={24}>
              <MFormInput
                label='Address Line 1'
                placeholder='Enter'
                value={form.values.address1}
                defaultValue={accountHolder?.physicalAddress?.address1}
                error={form.errors.address1}
                onChange={value => {
                  form.setFieldValue('address1', value);
                }}
                testId={`${isPrimary ? 'primary' : 'joint'}-account-physical-address1`}
              />
            </Col>
            <Col span={24}>
              <MFormInput
                label='Address Line 2 (Opt.)'
                placeholder='Enter'
                value={form.values.address2}
                defaultValue={accountHolder?.physicalAddress?.address2}
                error={form.errors.address2}
                onChange={value => {
                  form.setFieldValue('address2', value);
                }}
                testId={`${isPrimary ? 'primary' : 'joint'}-account-physical-address2`}
              />
            </Col>
            <Col span={24}>
              <MFormSelect
                label='Country'
                placeholder='Select'
                defaultValue={accountHolder?.physicalAddress?.country?.value ?? US_COUNTRY_VALUE}
                options={countryOptions}
                error={form.errors.country}
                onChange={value => {
                  form.setFieldValue('country', value);
                }}
                testId={`${isPrimary ? 'primary' : 'joint'}-account-physical-country`}
              />
            </Col>
            <Col span={24}>
              <MFormInput
                label='City'
                placeholder='Enter'
                value={form.values.city}
                defaultValue={accountHolder?.physicalAddress?.city}
                error={form.errors.city}
                onChange={value => {
                  form.setFieldValue('city', value);
                }}
                testId={`${isPrimary ? 'primary' : 'joint'}-account-physical-city`}
              />
            </Col>
            <Col span={24}>
              <MFormSelect
                label='State'
                placeholder='Select'
                value={form.values.state}
                defaultValue={accountHolder?.physicalAddress?.state?.value}
                options={US_COUNTRY_STATE_LIST}
                error={form.errors.state}
                onChange={value => {
                  form.setFieldValue('state', value);
                }}
                testId={`${isPrimary ? 'primary' : 'joint'}-account-physical-state`}
              />
            </Col>
            <Col span={24}>
              <MFormInput
                label='Postal Code'
                placeholder='Enter'
                value={form.values.postalCode}
                defaultValue={accountHolder?.physicalAddress?.postalCode}
                error={form.errors.postalCode}
                onChange={value => {
                  form.setFieldValue('postalCode', value);
                }}
                testId={`${isPrimary ? 'primary' : 'joint'}-account-physical-postal-code`}
              />
            </Col>
            <Col span={24}>
              <MFormSelect
                label='Citizenship'
                placeholder='Select'
                defaultValue={accountHolder?.citizenshipCountry?.value}
                options={citizenshipOptions}
                error={form.errors.citizenshipCountry}
                onChange={value => {
                  form.setFieldValue('citizenshipCountry', value);
                }}
                testId={`${isPrimary ? 'primary' : 'joint'}-account-physical-citizenship`}
              />
            </Col>
            <Col span={24}>
              <MFormSelect
                label='Birth Country'
                placeholder='Select'
                defaultValue={accountHolder?.birthCountry?.value}
                options={birthCountryOptions}
                error={form.errors.birthCountry}
                onChange={value => {
                  form.setFieldValue('birthCountry', value);
                }}
                testId={`${isPrimary ? 'primary' : 'joint'}-account-physical-birth-country`}
              />
            </Col>
            {!isAddressPopulated && isRequestMatched() && (
              <Col span={24}>
                <ErrorResponseAlert
                  error={createError ?? patchError}
                  name='physical'
                  onClick={onPopulatePhysicalAddress}
                />
              </Col>
            )}
            <Col span={24}>
              <MFormSaveButton<AccountHolderPhysicalAddressFormValues>
                loading={isUpsertLoading}
                onCancel={_onCancel}
                onSave={_onSave}
                isEditMode={shouldPatch}
                form={form}
                testId={`${isPrimary ? 'primary' : 'joint'}-account-physical-address`}
              />
            </Col>
          </>
        );
      }}
    </Formik>
  );
};
