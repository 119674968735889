import { call, put, SagaReturnType, select, takeEvery } from 'redux-saga/effects';
import { doSucceededGetAccount, toastMessagesAdd } from 'src/actions';
import { getClientApi } from 'src/data-communication';
import { AccountHolderApi } from 'src/data-communication/AccountHolderApi';
import {
  PatchAccountHolderDto,
  CreateAccountHolderPhysicalAddressDto,
  PatchAccountHolderPhysicalAddressDto,
  CreateAccountHolderMailingAddressDto,
  PatchAccountHolderMailingAddressDto,
  CreateAccountHolderTrustedContactDto,
  PatchAccountHolderTrustedContactDto,
  DeleteAccountHolderTrustedContactDto,
  CreateAccountHolderSuitabilityInformationDto,
  PatchAccountHolderSuitabilityInformationDto,
  CreateAccountHolderFinancialAssetDto,
  PatchAccountHolderFinancialAssetDto,
  CreateAccountHolderFinancialEmploymentDto,
  PatchAccountHolderFinancialEmploymentDto,
  CreateAccountholderDisclosureDto,
  PatchAccountHolderDisclosureDto,
} from 'src/dtos';
import { mapAccountDtoToModel } from 'src/mappers';
import {
  Account,
  AccountHolderTrustedContact,
  AccountHolderSuitabilityInformation,
  AccountHolderFinancialInformation,
} from 'src/models';
import { getPayloadFromErrorResponse } from 'src/utils';
import { v4 as uuidv4 } from 'uuid';

import { State, Type } from '../actions/utils';
import { SeverityEnum, TReduxAction } from '../typings/commonTypes';

import { safeSaga } from './utils';

const clientApi = getClientApi();

function* patchPersonalInformation(action: TReduxAction) {
  const { authToken } = yield select(state => state.auth.data);

  const dto: PatchAccountHolderDto = action.payload;
  const data: SagaReturnType<AccountHolderApi['patch']> = yield call(clientApi.accountHolder.patch, {
    ...dto,
    authToken,
  });

  yield put(
    toastMessagesAdd({
      key: uuidv4(),
      severity: SeverityEnum.Success,
      message: `Personal Information was successfully saved`,
    }),
  );
  yield put(doSucceededGetAccount(mapAccountDtoToModel(data)));
  yield put({
    type: State.actionSucceeded(Type.PATCH_ACCOUNT_HOLDER_PERSONAL_INFORMATION),
  });
}

function* createPhysicalAddress(action: TReduxAction) {
  try {
    const { authToken } = yield select(state => state.auth.data);
    const dto: CreateAccountHolderPhysicalAddressDto = action.payload;
    const data: SagaReturnType<AccountHolderApi['createPhysicalAddress']> = yield call(
      clientApi.accountHolder.createPhysicalAddress,
      {
        ...dto,
        authToken,
      },
    );

    yield put(
      toastMessagesAdd({
        key: uuidv4(),
        severity: SeverityEnum.Success,
        message: 'Physical Address Information was successfully saved',
      }),
    );
    yield put({
      type: State.actionSucceeded(Type.CREATE_ACCOUNT_HOLDER_PHYSICAL_ADDRESS),
    });
    yield put(doSucceededGetAccount(mapAccountDtoToModel(data)));
  } catch (error) {
    const payload = getPayloadFromErrorResponse(error);
    yield put({
      type: State.actionFailed(Type.CREATE_ACCOUNT_HOLDER_PHYSICAL_ADDRESS),
      payload,
    });
  }
}

function* patchPhysicalAddress(action: TReduxAction) {
  try {
    const { authToken } = yield select(state => state.auth.data);
    const dto: PatchAccountHolderPhysicalAddressDto = action.payload;
    const data: SagaReturnType<AccountHolderApi['patchPhysicalAddress']> = yield call(
      clientApi.accountHolder.patchPhysicalAddress,
      {
        ...dto,
        authToken,
      },
    );

    yield put({
      type: State.actionSucceeded(Type.PATCH_ACCOUNT_HOLDER_PHYSICAL_ADDRESS),
    });
    yield put(doSucceededGetAccount(mapAccountDtoToModel(data)));
  } catch (error: any) {
    const payload = getPayloadFromErrorResponse(error);
    yield put({
      type: State.actionFailed(Type.PATCH_ACCOUNT_HOLDER_PHYSICAL_ADDRESS),
      payload,
    });
  }
}

function* createMailingAddress(action: TReduxAction) {
  try {
    const { authToken } = yield select(state => state.auth.data);
    const dto: CreateAccountHolderMailingAddressDto = action.payload;
    const data: SagaReturnType<AccountHolderApi['createMailingAddress']> = yield call(
      clientApi.accountHolder.createMailingAddress,
      {
        ...dto,
        authToken,
      },
    );
    yield put(
      toastMessagesAdd({
        key: uuidv4(),
        severity: SeverityEnum.Success,
        message: 'Mailing Address Information was successfully saved',
      }),
    );
    yield put({
      type: State.actionSucceeded(Type.CREATE_ACCOUNT_HOLDER_MAILING_ADDRESS),
    });
    yield put(doSucceededGetAccount(mapAccountDtoToModel(data)));
  } catch (error) {
    const payload = getPayloadFromErrorResponse(error);
    yield put({
      type: State.actionFailed(Type.CREATE_ACCOUNT_HOLDER_MAILING_ADDRESS),
      payload,
    });
  }
}

function* patchMailingAddress(action: TReduxAction) {
  try {
    const { authToken } = yield select(state => state.auth.data);
    const dto: PatchAccountHolderMailingAddressDto = action.payload;
    const data: SagaReturnType<AccountHolderApi['patchMailingAddress']> = yield call(
      clientApi.accountHolder.patchMailingAddress,
      {
        ...dto,
        authToken,
      },
    );
    yield put(
      toastMessagesAdd({
        key: uuidv4(),
        severity: SeverityEnum.Success,
        message: `Mailing Address Information was successfully saved`,
      }),
    );
    yield put({
      type: State.actionSucceeded(Type.PATCH_ACCOUNT_HOLDER_MAILING_ADDRESS),
    });
    yield put(doSucceededGetAccount(mapAccountDtoToModel(data)));
  } catch (error: any) {
    const payload = getPayloadFromErrorResponse(error);
    yield put({
      type: State.actionFailed(Type.PATCH_ACCOUNT_HOLDER_MAILING_ADDRESS),
      payload,
    });
  }
}

function* createTrustedContact(action: TReduxAction) {
  try {
    const { authToken } = yield select(state => state.auth.data);
    const dto: CreateAccountHolderTrustedContactDto = action.payload;
    const data: SagaReturnType<AccountHolderApi['createTrustedContact']> = yield call(
      clientApi.accountHolder.createTrustedContact,
      {
        ...dto,
        authToken,
      },
    );
    yield put(
      toastMessagesAdd({
        key: uuidv4(),
        severity: SeverityEnum.Success,
        message: 'Trusted Contact Information was successfully saved',
      }),
    );
    yield put({
      type: State.actionSucceeded(Type.CREATE_ACCOUNT_HOLDER_TRUSTED_CONTACT),
    });
    const account: Account = yield select(state => state.accounts.item.data);
    yield put(
      doSucceededGetAccount({
        ...account,
        primaryAccountHolder: {
          ...account.primaryAccountHolder,
          trustedContact: new AccountHolderTrustedContact(data),
        },
      }),
    );
  } catch (error) {
    const payload = getPayloadFromErrorResponse(error);
    yield put({
      type: State.actionFailed(Type.CREATE_ACCOUNT_HOLDER_TRUSTED_CONTACT),
      payload,
    });
  }
}

function* patchTrustedContact(action: TReduxAction) {
  try {
    const { authToken } = yield select(state => state.auth.data);
    const dto: PatchAccountHolderTrustedContactDto = action.payload;
    const data: SagaReturnType<AccountHolderApi['patchTrustedContact']> = yield call(
      clientApi.accountHolder.patchTrustedContact,
      {
        ...dto,
        authToken,
      },
    );
    yield put({
      type: State.actionSucceeded(Type.PATCH_ACCOUNT_HOLDER_TRUSTED_CONTACT),
    });
    const account: Account = yield select(state => state.accounts.item.data);
    yield put(
      doSucceededGetAccount({
        ...account,
        primaryAccountHolder: {
          ...account.primaryAccountHolder,
          trustedContact: new AccountHolderTrustedContact(data),
        },
      }),
    );
  } catch (error) {
    const payload = getPayloadFromErrorResponse(error);
    yield put({
      type: State.actionFailed(Type.PATCH_ACCOUNT_HOLDER_TRUSTED_CONTACT),
      payload,
    });
  }
}

function* deleteTrustedContact(action: TReduxAction) {
  try {
    const { authToken } = yield select(state => state.auth.data);
    const dto: DeleteAccountHolderTrustedContactDto = action.payload;
    yield call(clientApi.accountHolder.deleteTrustedContact, {
      ...dto,
      authToken,
    });
    yield put(
      toastMessagesAdd({
        key: uuidv4(),
        severity: SeverityEnum.Success,
        message: 'Trusted Contact Person was successfully removed',
      }),
    );
    yield put({
      type: State.actionSucceeded(Type.DELETE_ACCOUNT_HOLDER_TRUSTED_CONTACT),
    });
    const account: Account = yield select(state => state.accounts.item.data);
    yield put(
      doSucceededGetAccount({
        ...account,
        primaryAccountHolder: { ...account.primaryAccountHolder, trustedContact: undefined },
      }),
    );
  } catch (error) {
    const payload = getPayloadFromErrorResponse(error);
    yield put({
      type: State.actionFailed(Type.DELETE_ACCOUNT_HOLDER_TRUSTED_CONTACT),
      payload,
    });
  }
}

function* createSuitabilityInformation(action: TReduxAction) {
  const { authToken } = yield select(state => state.auth.data);
  const dto: CreateAccountHolderSuitabilityInformationDto = action.payload;
  const data: SagaReturnType<AccountHolderApi['createSuitability']> = yield call(
    clientApi.accountHolder.createSuitability,
    {
      ...dto,
      authToken,
    },
  );
  yield put(
    toastMessagesAdd({
      key: uuidv4(),
      severity: SeverityEnum.Success,
      message: 'Suitability Information was successfully saved',
    }),
  );
  yield put({
    type: State.actionSucceeded(Type.CREATE_ACCOUNT_HOLDER_SUITABILITY_INFORMATION),
  });
  const account: Account = yield select(state => state.accounts.item.data);
  yield put(
    doSucceededGetAccount({
      ...account,
      primaryAccountHolder: {
        ...account.primaryAccountHolder,
        suitabilityInformation: new AccountHolderSuitabilityInformation(data),
      },
    }),
  );
}

function* patchSuitabilityInformation(action: TReduxAction) {
  const { authToken } = yield select(state => state.auth.data);
  const dto: PatchAccountHolderSuitabilityInformationDto = action.payload;
  const data: SagaReturnType<AccountHolderApi['patchSuitability']> = yield call(
    clientApi.accountHolder.patchSuitability,
    {
      ...dto,
      authToken,
    },
  );
  yield put(
    toastMessagesAdd({
      key: uuidv4(),
      severity: SeverityEnum.Success,
      message: 'Suitability Information was successfully saved',
    }),
  );
  yield put({
    type: State.actionSucceeded(Type.PATCH_ACCOUNT_HOLDER_SUITABILITY_INFORMATION),
  });
  const account: Account = yield select(state => state.accounts.item.data);
  yield put(
    doSucceededGetAccount({
      ...account,
      primaryAccountHolder: {
        ...account.primaryAccountHolder,
        suitabilityInformation: new AccountHolderSuitabilityInformation(data),
      },
    }),
  );
}

function* createFinancialInformationAssets(action: TReduxAction) {
  const { authToken } = yield select(state => state.auth.data);
  const dto: CreateAccountHolderFinancialAssetDto = action.payload;
  const data: SagaReturnType<AccountHolderApi['createFinancialAsset']> = yield call(
    clientApi.accountHolder.createFinancialAsset,
    {
      ...dto,
      authToken,
    },
  );
  yield put(
    toastMessagesAdd({
      key: uuidv4(),
      severity: SeverityEnum.Success,
      message: 'Assets Information was successfully saved',
    }),
  );
  yield put({
    type: State.actionSucceeded(Type.CREATE_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_ASSETS),
  });
  const account: Account = yield select(state => state.accounts.item.data);
  yield put(
    doSucceededGetAccount({
      ...account,
      primaryAccountHolder: {
        ...account.primaryAccountHolder,
        financialInformation: new AccountHolderFinancialInformation(data),
      },
    }),
  );
}

function* patchFinancialInformationAssets(action: TReduxAction) {
  const { authToken } = yield select(state => state.auth.data);
  const dto: PatchAccountHolderFinancialAssetDto = action.payload;
  const data: SagaReturnType<AccountHolderApi['patchFinancialAsset']> = yield call(
    clientApi.accountHolder.patchFinancialAsset,
    {
      ...dto,
      authToken,
    },
  );
  yield put(
    toastMessagesAdd({
      key: uuidv4(),
      severity: SeverityEnum.Success,
      message: `Assets Information was successfully saved`,
    }),
  );

  yield put({
    type: State.actionSucceeded(Type.PATCH_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_ASSETS),
  });
  const account: Account = yield select(state => state.accounts.item.data);
  yield put(
    doSucceededGetAccount({
      ...account,
      primaryAccountHolder: {
        ...account.primaryAccountHolder,
        financialInformation: new AccountHolderFinancialInformation(data),
      },
    }),
  );
}

function* createFinancialInformationEmployment(action: TReduxAction) {
  const { authToken } = yield select(state => state.auth.data);

  try {
    const dto: CreateAccountHolderFinancialEmploymentDto = action.payload;
    const data: SagaReturnType<AccountHolderApi['createFinancialEmployment']> = yield call(
      clientApi.accountHolder.createFinancialEmployment,
      {
        ...dto,
        authToken,
      },
    );
    yield put(
      toastMessagesAdd({
        key: uuidv4(),
        severity: SeverityEnum.Success,
        message: 'Employment Information was successfully saved',
      }),
    );
    yield put({
      type: State.actionSucceeded(Type.CREATE_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_EMPLOYMENT),
    });
    yield put(doSucceededGetAccount(mapAccountDtoToModel(data)));
  } catch (error) {
    const payload = getPayloadFromErrorResponse(error);
    yield put({
      type: State.actionFailed(Type.CREATE_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_EMPLOYMENT),
      payload,
    });
  }
}

function* patchFinancialInformationEmployment(action: TReduxAction) {
  const { authToken } = yield select(state => state.auth.data);

  try {
    const dto: PatchAccountHolderFinancialEmploymentDto = action.payload;
    const data: SagaReturnType<AccountHolderApi['patchFinancialEmployment']> = yield call(
      clientApi.accountHolder.patchFinancialEmployment,
      {
        ...dto,
        authToken,
      },
    );
    yield put(
      toastMessagesAdd({
        key: uuidv4(),
        severity: SeverityEnum.Success,
        message: `Employment Information was successfully saved`,
      }),
    );
    yield put({
      type: State.actionSucceeded(Type.PATCH_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_EMPLOYMENT),
    });
    yield put(doSucceededGetAccount(mapAccountDtoToModel(data)));
  } catch (error) {
    const payload = getPayloadFromErrorResponse(error);
    yield put({
      type: State.actionFailed(Type.PATCH_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_EMPLOYMENT),
      payload,
    });
  }
}

function* createDisclosures(action: TReduxAction) {
  const { authToken } = yield select(state => state.auth.data);

  const dto: CreateAccountholderDisclosureDto = action.payload;
  const data: SagaReturnType<AccountHolderApi['createDislosure']> = yield call(
    clientApi.accountHolder.createDislosure,
    {
      ...dto,
      authToken,
    },
  );

  yield put(
    toastMessagesAdd({
      key: uuidv4(),
      severity: SeverityEnum.Success,
      message: 'Disclosures Information was successfully saved',
    }),
  );
  yield put({
    type: State.actionSucceeded(Type.CREATE_ACCOUNT_HOLDER_DISCLOSURES),
  });
  yield put(doSucceededGetAccount(mapAccountDtoToModel(data)));
}

function* patchDisclosures(action: TReduxAction) {
  const { authToken } = yield select(state => state.auth.data);

  const dto: PatchAccountHolderDisclosureDto = action.payload;
  const data: SagaReturnType<AccountHolderApi['patchDisclosure']> = yield call(
    clientApi.accountHolder.patchDisclosure,
    {
      ...dto,
      authToken,
    },
  );

  yield put(
    toastMessagesAdd({
      key: uuidv4(),
      severity: SeverityEnum.Success,
      message: 'Disclosures Information was successfully saved',
    }),
  );
  yield put({
    type: State.actionSucceeded(Type.CREATE_ACCOUNT_HOLDER_DISCLOSURES),
  });
  yield put(doSucceededGetAccount(mapAccountDtoToModel(data)));
}

export function* registerAccountHolderSagas() {
  yield takeEvery(
    State.actionRequested(Type.PATCH_ACCOUNT_HOLDER_PERSONAL_INFORMATION),
    safeSaga(patchPersonalInformation, Type.PATCH_ACCOUNT_HOLDER_PERSONAL_INFORMATION),
  );

  yield takeEvery(State.actionRequested(Type.CREATE_ACCOUNT_HOLDER_PHYSICAL_ADDRESS), createPhysicalAddress);
  yield takeEvery(State.actionRequested(Type.PATCH_ACCOUNT_HOLDER_PHYSICAL_ADDRESS), patchPhysicalAddress);

  yield takeEvery(State.actionRequested(Type.CREATE_ACCOUNT_HOLDER_MAILING_ADDRESS), createMailingAddress);
  yield takeEvery(State.actionRequested(Type.PATCH_ACCOUNT_HOLDER_MAILING_ADDRESS), patchMailingAddress);

  yield takeEvery(State.actionRequested(Type.CREATE_ACCOUNT_HOLDER_TRUSTED_CONTACT), createTrustedContact);
  yield takeEvery(State.actionRequested(Type.PATCH_ACCOUNT_HOLDER_TRUSTED_CONTACT), patchTrustedContact);
  yield takeEvery(State.actionRequested(Type.DELETE_ACCOUNT_HOLDER_TRUSTED_CONTACT), deleteTrustedContact);

  yield takeEvery(
    State.actionRequested(Type.CREATE_ACCOUNT_HOLDER_SUITABILITY_INFORMATION),
    safeSaga(createSuitabilityInformation, Type.CREATE_ACCOUNT_HOLDER_SUITABILITY_INFORMATION),
  );
  yield takeEvery(
    State.actionRequested(Type.PATCH_ACCOUNT_HOLDER_SUITABILITY_INFORMATION),
    safeSaga(patchSuitabilityInformation, Type.PATCH_ACCOUNT_HOLDER_SUITABILITY_INFORMATION),
  );

  yield takeEvery(
    State.actionRequested(Type.CREATE_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_ASSETS),
    safeSaga(createFinancialInformationAssets, Type.CREATE_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_ASSETS),
  );
  yield takeEvery(
    State.actionRequested(Type.PATCH_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_ASSETS),
    safeSaga(patchFinancialInformationAssets, Type.PATCH_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_ASSETS),
  );
  yield takeEvery(
    State.actionRequested(Type.CREATE_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_EMPLOYMENT),
    createFinancialInformationEmployment,
  );
  yield takeEvery(
    State.actionRequested(Type.PATCH_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_EMPLOYMENT),
    patchFinancialInformationEmployment,
  );

  yield takeEvery(
    State.actionRequested(Type.CREATE_ACCOUNT_HOLDER_DISCLOSURES),
    safeSaga(createDisclosures, Type.CREATE_ACCOUNT_HOLDER_DISCLOSURES),
  );
  yield takeEvery(
    State.actionRequested(Type.PATCH_ACCOUNT_HOLDER_DISCLOSURES),
    safeSaga(patchDisclosures, Type.PATCH_ACCOUNT_HOLDER_DISCLOSURES),
  );
}
