import { cx } from '@emotion/css';
import { useAuthenticatedUserSelector } from 'src/hooks';
import { Account } from 'src/models';
import { AlignItems, Display, FlexDirection, JustifyContent } from 'src/styles';

import * as Styles from './Header.styles';

interface AccountHolderFullNameProps {
  alignment: 'start' | 'center';
  account?: Account;
}

export const AccountHolderFullName = ({ account, alignment }: AccountHolderFullNameProps) => {
  const { user } = useAuthenticatedUserSelector();

  const getFullName = () => {
    if (!account || !account.primaryAccountHolder) {
      return `${user?.firstName} ${user?.middleName ?? ''} ${user?.lastName} ${user?.suffix ?? ''}`;
    }

    return [
      account?.primaryAccountHolder.firstName,
      account?.primaryAccountHolder.middleName,
      account?.primaryAccountHolder.lastName,
      account?.primaryAccountHolder.suffix,
    ]
      .filter(value => value && value.trim().length > 0)
      .join(' ');
  };

  const getAccountType = () => {
    if (account?.type.isRetirement) {
      return account.retirementAccountType?.label ?? account.type.label;
    }

    return account?.type.label;
  };

  return (
    <div
      className={cx(
        Display.flex,
        JustifyContent.center,
        alignment === 'start' ? AlignItems.flexStart : AlignItems.center,
        FlexDirection.column,
        JustifyContent.flexStart,
      )}>
      <span className={Styles.fullName} data-testid={'header-menu-full-name'}>
        {getFullName()}
      </span>
      {account && (
        <span className={Styles.accountType} data-testid={'header-menu-account-type'}>
          {getAccountType()}
          {account.accountNumber ? ` • ${account.accountNumber}` : ''}
        </span>
      )}
    </div>
  );
};
