import { ErrorName } from 'src/constants';

export const getPayloadFromErrorResponse = (error: any) => {
  if (error?.response?.data) {
    return error.response.data;
  }

  if (error.code === 'ERR_NETWORK') {
    return { error: ErrorName.Network, message: 'Unable to complete the action. Network error occurred.' };
  }

  return {
    error: error.name ?? ErrorName.Unknown,
    message: error.message,
  };
};

export const isUnauthorizedResponseError = (error: any) => {
  if (error?.response?.status) {
    return error?.response?.status === 401;
  }

  return error?.statusCode === 401;
};
