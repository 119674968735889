import { put, takeEvery } from 'redux-saga/effects';
import { logout } from 'src/actions';
import { State, Type } from 'src/actions/utils';
import { isUnauthorizedResponseError } from 'src/utils';

function* handleUnauthorizedResponseError(action: any) {
  const { error } = action.payload;

  if (isUnauthorizedResponseError(error)) {
    yield put(logout());
  }
}

export function* registerCommonSagas() {
  // List of action types to watch for
  const actionTypesToWatch = [
    State.actionFailed(Type.CREATE_ACCOUNT_HOLDER_PHYSICAL_ADDRESS),
    State.actionFailed(Type.PATCH_ACCOUNT_HOLDER_PHYSICAL_ADDRESS),
    State.actionFailed(Type.CREATE_ACCOUNT_HOLDER_MAILING_ADDRESS),
    State.actionFailed(Type.PATCH_ACCOUNT_HOLDER_MAILING_ADDRESS),
    State.actionFailed(Type.CREATE_ACCOUNT_HOLDER_TRUSTED_CONTACT),
    State.actionFailed(Type.PATCH_ACCOUNT_HOLDER_TRUSTED_CONTACT),
    State.actionFailed(Type.DELETE_ACCOUNT_HOLDER_TRUSTED_CONTACT),
    State.actionFailed(Type.CREATE_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_EMPLOYMENT),
    State.actionFailed(Type.PATCH_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_EMPLOYMENT),
  ];

  yield takeEvery(actionTypesToWatch, handleUnauthorizedResponseError);
}
