import { useEffect, useState } from 'react';

import { Col } from 'antd';
import { Formik, FormikProps } from 'formik';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  doClearCreateAccountHolderTrustedContact,
  doClearPatchAccountHolderTrustedContact,
  doCreateAccountHolderTrustedContact,
  doPatchAccountHolderTrustedContact,
} from 'src/actions';
import {
  ErrorName,
  US_COUNTRY_LABEL,
  US_COUNTRY_STATE_LIST,
  US_COUNTRY_VALUE,
  US_PHONE_NUMBER_PREFIX,
} from 'src/constants';
import { AccountHolderPhoneNumberTypeDto, PhoneNumberTypeDto } from 'src/dtos';
import { MFormSaveButton, MFormInput, MFormSelect, MFormPhoneNumberInput, ErrorResponseAlert } from 'src/lib';
import { Account } from 'src/models';
import { assertNonNullable } from 'src/utils';

import * as Styles from './TrustedContact.styles';
import { upsertAccountHolderTrustedContactValidation } from './validations';

export const countryOptions = [{ label: US_COUNTRY_LABEL, value: US_COUNTRY_VALUE }];

interface AccountHolderTrustedContactFormValues {
  firstName?: string;
  lastName?: string;
  phones?: { type: PhoneNumberTypeDto; value: string; prefix: string }[];
  email?: string;
  address1?: string;
  address2?: string;
  country?: string;
  city?: string;
  state?: string;
  postalCode?: string;
}

export interface AccountHolderTrustedContactFormProps {
  account?: Account;
  onCancel?: () => void;
  onSave?: () => void;
}

export const TrustedContactForm = ({ account, onCancel, onSave }: AccountHolderTrustedContactFormProps) => {
  const dispatch = useDispatch();

  const isCreateTrustedContactLoading = useSelector((state: any) =>
    Boolean(state.accountHolders.createTrustedContact.__requested),
  );
  const succeededCreateTrustedContact = useSelector((state: any) =>
    Boolean(state.accountHolders.createTrustedContact?.__succeeded),
  );
  const createError = useSelector((state: any) => state.accountHolders.createTrustedContact?.error);

  const isPatchTrustedContactLoading = useSelector((state: any) =>
    Boolean(state.accountHolders.patchTrustedContact.__requested),
  );
  const succeededPatchTrustedContact = useSelector((state: any) =>
    Boolean(state.accountHolders.patchTrustedContact?.__succeeded),
  );
  const patchError = useSelector((state: any) => state.accountHolders.patchTrustedContact?.error);
  const deleteError = useSelector((state: any) => state.accountHolders.deleteTrustedContact?.error);

  const [initialValues, setInitialValues] = useState<AccountHolderTrustedContactFormValues>({});
  const [shouldPatch, setShouldPatch] = useState<boolean>(false);
  const [isAddressPopulated, setIsAddressPopulated] = useState<boolean>(false);

  const isTrustedContactAlreadySaved = () => !isEmpty(account?.primaryAccountHolder?.trustedContact);

  const _onCancel = (form: FormikProps<AccountHolderTrustedContactFormValues>) => {
    form.resetForm();

    if (onCancel) {
      onCancel();
    }
  };

  const _onSave = (form: FormikProps<AccountHolderTrustedContactFormValues>) => {
    form.submitForm();

    if (onSave) {
      onSave();
    }
  };

  useEffect(() => {
    setInitialValues({
      firstName: account?.primaryAccountHolder?.trustedContact?.firstName,
      lastName: account?.primaryAccountHolder?.trustedContact?.lastName,
      phones: account?.primaryAccountHolder?.trustedContact?.phones?.map(phone => ({
        type: phone.type,
        value: phone.phoneNumber,
        prefix: US_PHONE_NUMBER_PREFIX, // NOTE: user can only save US phone number
      })),
      email: account?.primaryAccountHolder?.trustedContact?.email,
      address1: account?.primaryAccountHolder?.trustedContact?.address1,
      address2: account?.primaryAccountHolder?.trustedContact?.address2,
      city: account?.primaryAccountHolder?.trustedContact?.city,
      country: account?.primaryAccountHolder?.trustedContact?.country?.value ?? US_COUNTRY_VALUE,
      state: account?.primaryAccountHolder?.trustedContact?.state?.value,
      postalCode: account?.primaryAccountHolder?.trustedContact?.postalCode,
    });
  }, [account?.primaryAccountHolder]);

  useEffect(() => {
    if (isTrustedContactAlreadySaved()) {
      setShouldPatch(true);
    }
  }, [account?.primaryAccountHolder]);

  useEffect(() => {
    if (succeededCreateTrustedContact || succeededPatchTrustedContact) {
      if (onCancel) {
        onCancel();
      }
    }
  }, [succeededCreateTrustedContact, succeededPatchTrustedContact]);

  useEffect(() => {
    return () => {
      dispatch(doClearCreateAccountHolderTrustedContact());
      dispatch(doClearPatchAccountHolderTrustedContact());
    };
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={upsertAccountHolderTrustedContactValidation}
      onSubmit={values => {
        assertNonNullable(account, 'Account');

        const dto = {
          ...values,
          phones: values.phones?.map(anItem => ({
            type: anItem.type,
            phoneNumber: anItem.value,
          })),
        };

        if (shouldPatch) {
          dispatch(
            doPatchAccountHolderTrustedContact({
              params: {
                id: account.id,
              },
              body: dto,
            }),
          );

          return;
        }

        dispatch(
          doCreateAccountHolderTrustedContact({
            params: {
              id: account.id,
            },
            body: dto,
          }),
        );
      }}>
      {form => {
        const onPopulateTrustedContact = () => {
          if (createError?.error === ErrorName.UspsAddressMismatch) {
            const message = JSON.parse(createError?.message);
            form.setFieldValue('address1', message.address.address1);
            form.setFieldValue('address2', message.address.address2);
            form.setFieldValue('city', message.address.city);
            form.setFieldValue('state', message.address.state);
            form.setFieldValue('postalCode', message.address.postalCode);
            setIsAddressPopulated(true);
          }

          if (patchError?.error === ErrorName.UspsAddressMismatch) {
            const message = JSON.parse(patchError?.message);
            form.setFieldValue('address1', message.address.address1);
            form.setFieldValue('address2', message.address.address2);
            form.setFieldValue('city', message.address.city);
            form.setFieldValue('state', message.address.state);
            form.setFieldValue('postalCode', message.address.postalCode);
            setIsAddressPopulated(true);
          }
        };

        useEffect(() => {
          setIsAddressPopulated(false);
        }, [createError, patchError]);

        return (
          <>
            <Col span={24}>
              <MFormInput
                testId={'account-first-name'}
                label='First Name'
                placeholder='Enter'
                value={form.values.firstName}
                defaultValue={initialValues.firstName}
                error={form.errors.firstName}
                onChange={value => {
                  form.setFieldValue('firstName', value);
                }}
              />
            </Col>
            <Col span={24}>
              <MFormInput
                testId={'account-last-name'}
                label='Last Name'
                placeholder='Enter'
                value={form.values.lastName}
                defaultValue={initialValues.lastName}
                error={form.errors.lastName}
                onChange={value => {
                  form.setFieldValue('lastName', value);
                }}
              />
            </Col>
            <Col span={24}>
              <MFormInput
                testId={'account-email'}
                label='Email'
                placeholder='Enter'
                value={form.values.email}
                defaultValue={initialValues.email}
                error={form.errors.email}
                onChange={value => {
                  form.setFieldValue('email', value);
                }}
              />
            </Col>
            <Col span={24}>
              <MFormPhoneNumberInput
                testId={'account-phone-number'}
                international={false}
                options={
                  form.values.phones ?? [
                    {
                      type: AccountHolderPhoneNumberTypeDto.Mobile,
                      value: '',
                      prefix: US_PHONE_NUMBER_PREFIX,
                    },
                  ]
                }
                onChange={value => {
                  form.setFieldValue('phones', value);
                }}
                error={form.errors.phones}
              />
            </Col>

            <Col span={24} className={Styles.title}>
              Address
            </Col>

            <Col span={24}>
              <MFormInput
                testId={'account-address1'}
                label='Address Line 1'
                placeholder='Enter'
                value={form.values.address1}
                defaultValue={initialValues.address1}
                error={form.errors.address1}
                onChange={value => {
                  form.setFieldValue('address1', value);
                }}
              />
            </Col>
            <Col span={24}>
              <MFormInput
                testId={'account-address2'}
                label='Address Line 2 (Opt.)'
                placeholder='Enter'
                value={form.values.address2}
                defaultValue={initialValues.address2}
                error={form.errors.address2}
                onChange={value => {
                  form.setFieldValue('address2', value);
                }}
              />
            </Col>
            <Col span={24}>
              <MFormSelect
                testId={'account-country'}
                label='Country'
                placeholder='Select'
                defaultValue={account?.primaryAccountHolder?.trustedContact?.country.value ?? US_COUNTRY_VALUE}
                options={countryOptions}
                error={form.errors.country}
                onChange={value => {
                  form.setFieldValue('country', value);
                }}
              />
            </Col>
            <Col span={24}>
              <MFormInput
                testId={'account-city'}
                label='City'
                placeholder='Enter'
                value={form.values.city}
                defaultValue={initialValues.city}
                error={form.errors.city}
                onChange={value => {
                  form.setFieldValue('city', value);
                }}
              />
            </Col>
            <Col span={24}>
              <MFormSelect
                testId={'account-state'}
                label='State'
                placeholder='Select'
                defaultValue={account?.primaryAccountHolder?.trustedContact?.state?.value}
                value={form.values.state}
                options={US_COUNTRY_STATE_LIST}
                error={form.errors.state}
                onChange={value => {
                  form.setFieldValue('state', value);
                }}
              />
            </Col>
            <Col span={24}>
              <MFormInput
                testId={'account-postal-code'}
                label='Postal Code'
                placeholder='Enter'
                value={form.values.postalCode}
                defaultValue={initialValues.postalCode}
                error={form.errors.postalCode}
                onChange={value => {
                  form.setFieldValue('postalCode', value);
                }}
              />
            </Col>
            {!isAddressPopulated && (
              <Col span={24}>
                <ErrorResponseAlert
                  error={createError ?? patchError ?? deleteError}
                  name='trusted contact'
                  onClick={onPopulateTrustedContact}
                />
              </Col>
            )}
            <Col span={24}>
              <MFormSaveButton<AccountHolderTrustedContactFormValues>
                loading={isCreateTrustedContactLoading || isPatchTrustedContactLoading}
                onCancel={_onCancel}
                onSave={_onSave}
                isEditMode
                form={form}
                testId={'trusted-contact'}
              />
            </Col>
          </>
        );
      }}
    </Formik>
  );
};
