import { getAuthToken } from '../sagas/utils';

export default {
  auth: {
    data: { authToken: getAuthToken() },
  },
  user: {
    signUp: {},
    patchAddress: {},
    toggleMfaConfig: {},
    authenticated: {},
    login: {},
    confirmMfaCode: {},
    resendMfaCode: {},
    forgotPassword: {},
    resetPassword: {},
    emailChange: {},
    emailConfirm: {},
    updatePhoneNumber: {},
  },
  accounts: {
    list: [],
    item: {},
    create: {},
    patch: {},
    patchJointInterest: {},
    patchBeneficiaries: {},
    submit: {},
    downloadSignature: {},
    switch: {},
  },
  accountHolders: {
    patch: {},
    createPhysicalAddress: {},
    patchPhysicalAddress: {},
    createMailingAddress: {},
    patchMailingAddress: {},
    createTrustedContact: {},
    patchTrustedContact: {},
    deleteTrustedContact: {},
    createSuitabilityInformation: {},
    patchSuitabilityInformation: {},
    createFinancialInformationAssets: {},
    patchFinancialInformationAssets: {},
    createFinancialInformationEmployment: {},
    patchFinancialInformationEmployment: {},
    createDisclosures: {},
    patchDisclosures: {},
  },
  accountDetails: {
    accountBalance: {},
    accountValue: {},
    withdrawalFunds: {},
  },
  statements: {
    tradeConfirmations: {
      list: {},
      downloads: [],
    },
    monthlyStatements: {
      list: {},
      downloads: [],
    },
    taxDocuments: {
      list: {},
      downloads: [],
    },
  },
  cashiering: {
    achRelationships: {},
    createACHRelationship: {},
    editACHRelationship: {},
    unlinkACHRelationship: {},
    plaidLinkToken: {},
    transfers: {},
    transferDetails: {},
  },
  offers: {
    offersList: {},
    offerDetails: {},
    offerDocuments: {},
    options: {},
  },
  offerWatches: {
    list: {},
    createOfferWatch: {},
    deleteOfferWatch: {},
  },
  investigations: {
    list: {},
  },
  configs: {
    configsList: {},
  },
  quoteMedia: {
    snapQuote: {},
    enhancedSnapQuote: {},
    chartSnapQuote: {},
  },
  orders: {
    item: {},
    list: {},
    positions: {},
    review: {},
    created: {},
    updated: {},
  },
  offerOrders: {
    details: {},
    list: {},
    create: {},
    update: {},
    delete: {},
    createCardPaymentIntent: {},
    updateCardPaymentIntent: {},
    submitCardPayment: false,
    confirmCardPayment: {},
    intent: null,
  },
  toastMessages: {
    list: [],
    clearList: [],
    open: null,
    close: null,
  },
  app: {
    redirectToOffer: {},
    redirectToOfferOrder: {},
    redirectToEmailConfirm: null,
    onboardStep: null,
    offerInvestReferrer: null,
  },
};
