import { cx } from '@emotion/css';
import { AccountHolderAddressDto } from 'src/dtos';
import { ClientServicesLink } from 'src/lib';

import { Font, Spacing } from '../styles';

export const renderAddressMismatchErrorMessage = ({
  dto,
  onClick,
  section,
}: {
  dto: AccountHolderAddressDto;
  onClick: () => void;
  section: string;
}): JSX.Element => (
  <div>
    Invalid {section} address. USPS found the following address: <br />
    {dto.address1}, <br />
    {dto.address2 && `${dto.address2},`} {dto.address2 && <br />}
    {dto.city}, <br />
    {dto.state}, <br />
    {dto.postalCode}. <br />
    If address is correct, please click{' '}
    <a onClick={onClick} className={cx(Spacing.p4, Font.lg)}>
      here
    </a>{' '}
    to update the form.
  </div>
);

export const renderAddressNotFoundErrorMessage = (): JSX.Element => (
  <p>
    Invalid address. USPS did not find address as it does not contain a valid combination of City, State and Postal
    Code. Please contact <ClientServicesLink /> to resolve. You will not be able to &quot;Save&quot; or Complete this
    application until address is resolved.
  </p>
);

export const renderNetworkErrorMessage = (): JSX.Element => (
  <p>
    There was an error connecting to the server. Please make sure that your Internet connection is stable and try again.
    If the problem persists, please try refreshing the page or contact <ClientServicesLink />.
  </p>
);

export const renderUnknownErrorMessage = (value: any): JSX.Element => {
  if (value.message) {
    return (
      <p>
        Unknown error occurred (error: {value.message}). Please try again later. If the problem persists, please contact{' '}
        <ClientServicesLink /> to resolve.
      </p>
    );
  }

  return (
    <p>
      Unknown error occurred(error: {value}). Please try again later or contact <ClientServicesLink /> to resolve.
    </p>
  );
};
