import moment from 'moment';

export namespace DateConstant {
  export const ISO_1806_FORMAT = 'YYYY-MM-DD';
  export const US_FORMAT = 'MM/DD/yyyy';
  export const DEFAULT_DATETIME_FORMAT = 'MM/DD/YYYY hh:mm A';
  export const THIS_YEAR_START_DATE = moment().startOf('year').format(ISO_1806_FORMAT);
  export const TODAY = moment().format(ISO_1806_FORMAT);
  export const BIRTH_DATE = 'YYYY-MM-DD';
}

export enum ErrorCode {
  BEFORE_LOAD_STRIPE = '1050',
  LOAD_STRIPE = '1051',
  LOAD_STRIPE_ELEMENT = '1057',
  CREATE_OFFER_ORDER_CARD_PAYMENT_INTENT = '1052',
  BEFORE_UPDATE_OFFER_ORDER_CARD_PAYMENT_INTENT = '1053',
  UPDATE_OFFER_ORDER_CARD_PAYMENT_INTENT = '1054',
  BEFORE_CONFIRM_CARD_PAYMENT = '1055',
  CONFIRM_CARD_PAYMENT = '1056',
  URL_SEARCH_PARAMETER_NOT_FOUND = '1058',
  UPLOAD_INVESTIGATION_DOCUMENT = '1059',
}

export enum ErrorName {
  UspsAddressNotFound = 'UspsAddressNotFoundError',
  UspsAddressMismatch = 'UspsAddressMismatchError',
  Network = 'NetworkError',
  Unknown = 'UnknownError',
}

export enum ConfirmCardPaymentErrorCause {
  GENERIC_DECLINE = 'generic_decline',
  INSUFFICIENT_FUNDS = 'insufficient_funds',
  LOST_CARD = 'lost_card',
  STOLEN_CARD = 'stolen_card',
  EXPIRED_CARD = 'expired_card',
  INCORRECT_CVC = 'incorrect_cvc',
  PROCESSING_ERROR = 'processing_error',
  INCORRECT_NUMBER = 'incorrect_number',
  CARD_VELOCITY_EXCEEDED = 'card_velocity_exceeded',
  AUTHENTICATION_FAILURE = 'payment_intent_authentication_failure',
  UNKNOWN = 'unknown',
}

export enum DefinitionConstant {
  CASH_AVAILABLE = 'Cash Available is your cash balance minus any pending cash withdrawals and minus any pending buy orders',
  AVAILABLE_FUNDS_FOR_WITHDRAWAL = 'Funds Available for Withdrawal is your cash balance minus any pending cash withdrawals, minus any pending buy orders and do not include unsettled account funds',
}

export namespace FilterBarConstant {
  export enum ShowFilterCategory {
    DepositTransfer = 'DepositTransfer',
    WithdrawalTransfer = 'WithdrawalTransfer',
    OfferOrder = 'OfferOrder',
    BuyOrder = 'BuyOrder',
    SellOrder = 'SellOrder',
  }

  export enum ByFilterCategory {
    MoneyTransfer = 'MoneyTransfer',
    OfferOrder = 'OfferOrder',
    Order = 'Order',
  }

  export enum SortByCategory {
    Newest = 0,
    Oldest,
  }

  export enum SortByLabel {
    Newest = 'Newest',
    Oldest = 'Oldest',
  }
}

export const SUFFIX_LIST = ['Jr', 'Sr', 'I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX'];

export const US_PHONE_NUMBER_PREFIX = '+1';
export const US_COUNTRY_LABEL = 'United States';
export const US_COUNTRY_VALUE = 'USA';

export const US_COUNTRY_STATE_LIST = [
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'Delaware', value: 'DE' },
  { label: 'District of Columbia', value: 'DC' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Puerto Rico', value: 'PR' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Virgin Islands, U.S.', value: 'VI' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' },
];

export namespace ConfigConstant {
  export const AUTH_TOKEN_KEY = 'authToken';
}
