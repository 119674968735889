import { AccountBalanceUrlConstant } from 'src/constants';
import {
  AccountBalanceDto,
  GetAccountBalanceDto,
  GetAccountBalanceListDto,
  GetAccountBalanceListResponseDto,
  GetAccountWithdrawalFundsBalanceDto,
  GetAccountWithdrawalFundsBalanceResponseDto,
} from 'src/dtos';
import { ConfigService } from 'src/services';
import { httpRequest, replacePlaceholders, WithAuthToken } from 'src/utils';

export class BalanceApi {
  private _configService: ConfigService;
  constructor(configService: ConfigService) {
    this._configService = configService;
  }

  async retrieve({ params, authToken }: WithAuthToken<GetAccountBalanceDto>): Promise<AccountBalanceDto> {
    const { accountId } = params;

    const url = replacePlaceholders(AccountBalanceUrlConstant.RETRIEVE, { accountId });

    return httpRequest<AccountBalanceDto>({ method: 'GET', url, authToken });
  }

  async list({ body, authToken }: WithAuthToken<GetAccountBalanceListDto>): Promise<GetAccountBalanceListResponseDto> {
    return httpRequest<GetAccountBalanceListResponseDto>({
      method: 'POST',
      url: AccountBalanceUrlConstant.LIST,
      authToken,
      body,
    });
  }

  async retrieveWithdrawalFunds({
    params,
    authToken,
  }: WithAuthToken<GetAccountWithdrawalFundsBalanceDto>): Promise<GetAccountWithdrawalFundsBalanceResponseDto> {
    const { accountId } = params;

    const url = replacePlaceholders(AccountBalanceUrlConstant.RETRIEVE_WITHDRAWAL_FUNDS, { accountId });

    return httpRequest<GetAccountWithdrawalFundsBalanceResponseDto>({ method: 'GET', url, authToken });
  }
}
