import { AnyAction } from 'redux';
import { GetAccountWithdrawalFundsBalanceDto, GetAccountWithdrawalFundsBalanceResponseDto } from 'src/dtos';

import { createAction, State, Type } from './utils';

export const doGetAccountBalance = (accountId?: number): AnyAction =>
  createAction(State.actionRequested(Type.ACCOUNTS_GET_BALANCE), { accountId });

export const doSucceededAccountBalance = (payload?: any): AnyAction =>
  createAction(State.actionSucceeded(Type.ACCOUNTS_GET_BALANCE), payload);

export const doClearAccountBalance = (): AnyAction => createAction(State.actionCleared(Type.ACCOUNTS_GET_BALANCE), {});

export const refreshAccountBalance = (accountId?: number): AnyAction =>
  createAction(Type.REFRESH_ACCOUNT_BALANCE, { accountId });

export const accountsClearValues = (): AnyAction => createAction(State.actionCleared(Type.SET_ACCOUNT_VALUE));

export const accountsSetValues = (payload: any): AnyAction =>
  createAction(State.actionSucceeded(Type.SET_ACCOUNT_VALUE), payload);

export const doGetAccountWithdrawalFundsBalance = (payload: GetAccountWithdrawalFundsBalanceDto): AnyAction =>
  createAction(State.actionRequested(Type.GET_ACCOUNT_WITHDRAWAL_FUNDS_BALANCE), payload);

export const doClearGetAccountWithdrawalFundsBalance = (): AnyAction =>
  createAction(State.actionCleared(Type.GET_ACCOUNT_WITHDRAWAL_FUNDS_BALANCE), {});

export const doSucceededGetAccountWithdrawalFundsBalance = (
  payload: GetAccountWithdrawalFundsBalanceResponseDto,
): AnyAction => createAction(State.actionSucceeded(Type.GET_ACCOUNT_WITHDRAWAL_FUNDS_BALANCE), payload);
