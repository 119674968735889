import { cx } from '@emotion/css';
import { Col, Row } from 'antd';
import { DocumentUriConstant } from 'src/constants';
import { useAccountSelector } from 'src/hooks';
import { ClientServicesLink, MFormSection } from 'src/lib';
import { Font, Heading, Spacing } from 'src/styles';

export const AccountBeneficiariesSection = () => {
  const { account } = useAccountSelector();

  if (!account?.type.isRetirement) {
    return null;
  }

  return (
    <>
      <Row className={cx(Heading.title, Spacing.my32)} data-testid={'account-ira-beneficiaries-title'}>
        IRA Beneficiaries (Optional)
      </Row>
      <MFormSection isEditable={false} isEditMode={false} testId={'account-ira-beneficiaries'}>
        <Row>
          <Col span={24}>
            <p className={Font.h1} data-testid={'account-ira-beneficiaries-description'}>
              Please fill out and sign the{' '}
              <a href={DocumentUriConstant.IRA_BENEFICIARY_DESIGNATION_FORM} target='_blank' rel='noreferrer'>
                IRA Beneficiary Designation Form{' '}
              </a>
              if you would like to add account beneficiaries to your account in the future. Once your My IPO account is
              active please email it to <ClientServicesLink />.
            </p>
          </Col>
        </Row>
      </MFormSection>
    </>
  );
};
